import React from 'react'
import PropTypes from 'prop-types'
import '../../styles/main.scss'
import Image from '../image'

const ClientsBox = (props) => (
    <div  className={`${props.boxClass} wow fadeInUp`}>
        <Image type="logo" src={props.logo} class={props.imgClass} alt=''/>
    </div>
)

ClientsBox.propTypes = {
    boxClass: PropTypes.string,
    logo: PropTypes.string.isRequired,
    imgClass: PropTypes.string,
}

export default ClientsBox