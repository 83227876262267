import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import '../styles/main.scss'
import SpecCarouselItem from '../components/index-page/spec-carousel-item'
import ClientsBox from '../components/index-page/clients-box'
import Img from 'gatsby-image'
import Image from '../components/image'
import carouselImg1 from '../images/ranking3.jpg'
import carouselImg2 from '../images/header_01.jpg'
import carouselImg3 from '../images/header_02.jpg'
import carouselImg4 from '../images/header_03a.jpg'
// import carouselImg3 from '../images/header_03.jpg'
import carouselImg5 from '../images/header_04.jpg'
import portret from '../images/adam-swiecki-portret.png'
import logos from '../js/logos'
import utils from '../js/utils'
import mainLogo from '../images/logo.svg'
import AnimateComponent from '../components/animate-component'
import {
  isSafari,
} from 'react-device-detect'


class IndexPage extends Component {

  componentDidMount() {
    this.initCarousels()
  }

  componentWillUnmount() {
    this.unmountCarousels()
  }

  initCarousels = () => {
    if (this.props.location.pathname === '/') {
      const carousel = require('../js/carousel')
      this.headerCarousel = new carousel('header')
      this.clientsCarousel = new carousel('clients')
      this.servicesCarousel = new carousel('services')
      this
        .headerCarousel
        .run()
      this
        .clientsCarousel
        .run()
      this
        .servicesCarousel
        .run()
    }
  }

  unmountCarousels = () => {
    this
      .headerCarousel
      .stop()
    this.headerCarousel = null
    this
      .clientsCarousel
      .stop()
    this.clientsCarousel = null
    this
      .servicesCarousel
      .stop()
    this.servicesCarousel = null
  }

  render() {

    // const posts = this
    //     .props
    //     .data
    //     .wordpressPage
    //     .acf
    //     .slider
    //     .map(slide => {
    //         const linkType = slide.link_type;
    //         let link = '';
    //         switch (linkType) {
    //             case 'news':
    //                 link = slide.link
    //                     ? slide
    //                         .link
    //                         .split('com/')[1]
    //                     : '';
    //                 break;
    //             case 'blog':
    //                 link = slide.link
    //                     ? `blog/${slide
    //                         .link
    //                         .split('com/')[1]}`
    //                     : '';
    //                 break;
    //             case 'other':
    //                 link = slide.other_link;
    //         }
    //         slide.link = link;
    //         return slide;
    //     });
    const posts = this
      .props
      .data
      .wordpressPage
      .acf
      .slider

    const movie = (this
      .props
      .data
      .wordpressPage
      .acf
      .film_home) && this
      .props
      .data
      .wordpressPage
      .acf
      .film_home

    const check_movie =  this
      .props
      .data
      .wordpressPage
      .acf
      .pokaz_film

    const acf_fields = this
      .props
      .data
      .wordpressPage
      .acf

    let clientsCarouseBoxes = utils.chunkArray((Object.keys(logos)).slice(0, 36).map((el, i) => {
      return (<ClientsBox
        key={i}
        logo={Object.keys(logos)[i]}
        boxClass='carousel__box'
        imgClass='carousel__img' />)
    }), 3)

    let clientsCarouselBoxes2 = utils.chunkArray(acf_fields.zaufali_nam_klienci.map((el, i) => {
      return (<ClientsBox
          key={i}
          logo={el.logo.localFile.publicURL}
          boxClass='carousel__box'
          imgClass='carousel__img' />)
    }), 3)
    const clientsSlidesCount = Math.ceil(acf_fields.zaufali_nam_klienci.length / 9)
    let itemList = [];
    if ( clientsSlidesCount > 1 ) {
      for (let i = 2; i <= clientsSlidesCount; i++) {
        itemList.push(<p className='navigation__control' role='button' tabIndex='0'>{String(i).padStart(2, '0')}</p>);
      }
    }

    return (
      <Layout>
        <SEO
          title='Home | Świecki Kancelaria'
          keywords={['kancelaria prawna', 'kancelaria Łódź', 'porady prawne', 'dobry adwokat']} />
        <AnimateComponent>
          {movie && check_movie &&
          <section className='movie wow fadeInUp'>
            <video loop={true} autoPlay={true} muted={true} playsInline={true}>
              <source src={movie.localFile.publicURL} type='video/mp4' />
            </video>
          </section>
          }
          <header className='header' id='header'>

            <div className='wow fadeInUp'>


              <div className='header__container'>


                <div className='header__carousel carousel'>
                  <div className='carousel__container'>
                    {posts.map((item, i) => (

                      <div key={i} className='slider-item carousel__item'>
                        <Link
                          disabled={!item.link}
                          to={`${item.link
                            ? item.link
                            : ''}`}>
                          <div className='slider-item__left'>
                            <div className='slider-item__left-inner'>
                              {item.slide_title
                                ? <h2 className='carousel__slide-title'>{item.slide_title}</h2>
                                : null}
                              <div className='slide-content'>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: `${item.slide_text}`,
                                  }} />
                                {item.link
                                  ? <a href={item.link}>Czytaj więcej</a>
                                  : null}

                              </div>
                            </div>
                          </div>
                          <div className='slider-item__right'>
                            {item.slide_image
                              ? <img
                                alt=''
                                src={item.slide_image.localFile.publicURL}
                                className='carousel__item-image'
                                alt='' />
                              : <img alt='' src={carouselImg3} className='carousel__item-image' alt='' />
                            }
                          </div>
                        </Link>
                      </div>
                    ))}

                  </div>

                </div>
              </div>

              <div className='header__all-nav'>
                <div className='header__navigation navigation'>
                  <div className='navigation__controls'>
                    <p role='button' tabIndex='0' className='navigation__control active'>01</p>
                    <div className='navigation__progress-bar animate'></div>
                    {posts
                      .slice(0, -1)
                      .map((el, i) => (
                        <p key={i} role='button' tabIndex='0' className='navigation__control'>0{i + 2}</p>
                      ))}
                  </div>
                </div>
                <div className='navigation-arrows'>
                  <div
                    className='carousel__arrow carousel__arrow--left carousel-left'
                    role='button'
                    tabIndex='0'>
                    <Image type='icon' src='arrowLeft' alt='left' class='' />
                  </div>
                  <div
                    className='carousel__arrow carousel__arrow--right carousel-right'
                    role='button'
                    tabIndex='0'>
                    <Image type='icon' src='arrowRight' alt='right' class='' />
                  </div>
                </div>

              </div>

            </div>
          </header>
          {/*{movie &&*/}
          {/*<section className='movie wow fadeInUp'>*/}
          {/*  <video loop={true} autoPlay={true} muted={true} playsInline={true}>*/}
          {/*    <source src={movie.localFile.publicURL} type='video/mp4' />*/}
          {/*  </video>*/}
          {/*</section>*/}
          {/*}*/}
          <section className='cancellary'>
            <div className='cancellary__container'>
              <div delay='300' className='cancellary__photo wow fadeInUp'>
                <Img fluid={this.props.data.imageHome1.childImageSharp.fluid} />
              </div>
              <div delay='0' className='cancellary__content wow fadeInUp'>
                <div className='cancellary__decoration' aria-hidden='true'>&#167;1
                </div>
                <h3>Kancelaria</h3>
                <h4>Działamy w sposób dynamiczny, reagując nie tylko na pojawiające się w
                  konkretnej sytuacji problemy prawne, lecz również zapobiegając ich pojawianiu
                  się na wczesnym etapie.</h4>
                <p>
                  Chętnie podejmujemy się prowadzenia spraw o charakterze nowatorskim, tych
                  uznawanych za trudne i wymagające podejścia strategicznego oraz wiedzy
                  interdyscyplinarnej
                </p>
                <p>
                  <Link to='/kancelaria/'>Czytaj więcej</Link>
                </p>
              </div>
            </div>
          </section>
          <section className='team'>
            <div className='team__container'>
              <div delay='0' className='team__content wow fadeInUp'>
                <div className='team__decoration' aria-hidden='true'>&#167;2
                </div>
                <h3>Solidny zespół</h3>
                <h4>Najlepiej o naszej dobrej pracy świadczy stale powiększające się grono Klientów.
                </h4>
                <p>
                  Zaufały nam międzynarodowe korporacje, instytucje państwowe oraz rodzinne
                  przedsiębiorstwa. Pomogliśmy także wielu klientom indywidualnym.
                </p>
                <p>
                  <Link to='/zespol/'>Czytaj więcej</Link>
                </p>
              </div>
              <div delay='300' className='team__photo wow fadeInUp'>
                <Img
                  fluid={this.props.data.imageHome2.childImageSharp.fluid}
                  className='team__photo-img'
                  alt='Grupa pięciu prawników, siedzącychw sali obrad przy drewnianym stole.' />
              </div>
            </div>
          </section>
          <section className='specialization'>
            <div delay='0' className='specialization__container wow fadeInUp'>
              <div className='specialization__photo'>
                <picture>
                  <source media='(max-width: 500x)' srcSet='img/zdjecia/home3-small.png' />
                  <Img
                    fluid={this.props.data.imageHome4.childImageSharp.fluid}
                    className='team__photo-img'
                    alt='Dwoje ludzi siedzaych za biurkiem prz komputerze. Mężczyzna wskazuje palcem na monitor, mówiąc coś do kobiety obok.' />
                </picture>
              </div>
              <div className='specialization__content'>
                <div className='specialization__decoration' aria-hidden='true'>&#167;3
                </div>
                <h3>Wąska specjalizacja</h3>
                <h4>Klienci doceniają u nas umiejętność kompleksowego rozwiązywania problemów. W
                  zależności od stopnia skomplikowania sprawy tworzymy zespół prawników będących
                  ekspertami w swojej dziedzinie.</h4>
                <p>W gronie naszych prawników są specjaliści z zakresu poszczególnych dziedzin
                  prawa. Kancelaria współpracuje z doradcami podatkowymi, audytorami, ekonomistami
                  oraz innymi ekspertami, którzy w razie potrzeby stanowią wsparcie w wykonywaniu
                  zadań powierzonych przez Klienta.</p>
                <p>
                  <Link to='/specjalizacje/'>Czytaj więcej</Link>
                </p>
              </div>
            </div>
          </section>
          <div className='quote'>
            <div className='quote__container'>
              <span className='quote__decoration' aria-hidden='true'>,,</span>
              <p>Naczelną zasadą w kancelarii jest indywidualne podejście do każdej sprawy i
                każdego klienta.</p>
              <div delay='0' className='quote__author wow fadeInUp'>
                <img
                  src={portret}
                  className='quote__photo'
                  alt='portert prawnika - Adama Świeckigo' />
                <p>Adam Świecki
                  <br />
                  <span>Adwokat</span>
                </p>
              </div>
            </div>
          </div>
          <section className='services' id='services'>
            <div delay='0' className='services__container wow fadeInUp'>
              <div className='services__content'>
                <h4>Dlaczego my?</h4>
                <div className='services__text-container'>

                  <div>
                    <h5>Zespół</h5>
                    <p>Jesteśmy dużą kancelarią</p>
                    <p>
                      <Link to='/zespol'>Czytaj więcej</Link>
                    </p>
                  </div>
                  <div>
                    <h5>Doświadczenie</h5>
                    <p>Zaufało nam wielu klientów</p>
                    <p>
                      <Link to='/klienci'>Czytaj więcej</Link>
                    </p>
                  </div>
                  <div>
                    <h5>Specjalizacja</h5>
                    <p>Jesteśmy ekspertami w kluczowych dziedzinach prawa</p>
                    <p>
                      <Link to='/specjalizacje'>Czytaj więcej</Link>
                    </p>
                  </div>

                </div>
              </div>
              <div className='services__controls-container'>
                <div className='services__controls'>
                  <div
                    className='services__arrow services__arrow--left carousel-left'
                    role='button'
                    tabIndex='0'>
                    <Image type='icon' src='arrowLeftGreen' alt='left' class='' />
                  </div>
                  <div
                    className='services__arrow services__arrow--right carousel-right'
                    role='button'
                    tabIndex='0'>
                    <Image type='icon' src='arrowRightGreen' alt='right' class='' />
                  </div>
                </div>
              </div>
              <div className='services__carousel carousel wow fadeInUp'>
                <div className='carousel__container'>
                  <SpecCarouselItem
                    spec='budownictwo,zamówieniaPubliczne,nieruchomości,spółkiHandlowe' />
                  <SpecCarouselItem spec='umowy,przestępstwaGospodarcze,podatki,rodo' />
                  <SpecCarouselItem
                    spec='prawoPracy,sporySądoweIarbitrazowe,prawoRodzinne,wypadkiDrogowe' />
                  <SpecCarouselItem spec='umowy,przestępstwaGospodarcze,podatki,rodo' />
                  <SpecCarouselItem
                    spec='prawoPracy,sporySądoweIarbitrazowe,prawoRodzinne,wypadkiDrogowe' />
                  <SpecCarouselItem
                    spec='budownictwo,zamówieniaPubliczne,nieruchomości,spółkiHandlowe' />
                </div>
              </div>
            </div>
          </section>
          <section className='clients' id='clients'>
            <div className='clients__container'>
              <div className='clients__content wow fadeInUp'>
                <h4>{acf_fields.zaufali_nam_naglowek}</h4>
                <p>{acf_fields.zaufali_nam_paragraf}</p>
                <p>
                  <Link to={acf_fields.zaufali_nam_link.url} className="clients__read_more">{acf_fields.zaufali_nam_link.title}</Link>
                </p>
              </div>
              <div delay='300' className='clients__carousel carousel wow fadeInUp'>
                <div className='carousel__container'>
                  {utils.chunkArray(clientsCarouselBoxes2, 3).map((group, i) => (
                      <div className='carousel__item' key={`outer-column-${i}`}>
                        {group.map((item, j) => (
                            <div className='carousel__col' key={`column-${j}`}>
                              {item}
                            </div>
                        ))}
                      </div>
                  ))}
                </div>
                {}
                <div className='carousel__navigation navigation'>
                  <div className='navigation__controls'>
                    <p className='navigation__control active' role='button' tabIndex='0'>01</p>
                    <div className='navigation__progress-bar animate'></div>
                    {itemList}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </AnimateComponent>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
    query {
        imageHome1: file(relativePath: {eq: "images/adam-gabinet-home.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        imageHome2: file(relativePath: {eq: "images/adam-sala-home.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        imageHome3: file(relativePath: {eq: "images/adam-biurko-home.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        imageHome4: file(relativePath: {eq: "images/swiecki_home8.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 900) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        allWordpressWpAktualnosci( sort: {fields: date, order: DESC} ) {
            edges {
                node {
                    slug
                    acf {
                        slider_image {
                            localFile {
                                publicURL
                            }
                        }
                        slider_title
                        slider_title_color
                        show_in_slider

                    }

                }

            }
        }

        wordpressPage( slug: {eq: "home"}) {
            id
            title
            content
            excerpt
            date
            modified
            slug
            status
            acf {
                pokaz_film
                film_home {
                    localFile {
                        publicURL
                    }
                }
                slider {
                    slide_title
                    slide_text
                    link
                    slide_image {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 294, quality: 90) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        },
                        localFile {
                            publicURL
                        }
                    }
                }
                zaufali_nam_naglowek
                zaufali_nam_paragraf
                zaufali_nam_link {
                  title
                  url
                  target
                }
                zaufali_nam_klienci {
                  logo {
                    localFile {
                      publicURL
                    }
                  }
                }
            }
        }


    }
`
