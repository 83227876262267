import React from 'react'
import PropTypes from 'prop-types'
import '../../styles/main.scss'
import Img from 'gatsby-image'
import { StaticQuery, graphql, Link } from 'gatsby'
import utils from '../../js/utils'
import Image from '../image'

 const SpecCarouselItem  = (props) => (
    <StaticQuery
    query={graphql`
      query carouselQuery {
          budownictwo: file(relativePath: {eq: "images/specializationsCarousel/budownictwo.png"}) {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          zamowieniaPubliczne: file(relativePath: {eq: "images/specializationsCarousel/zamowienia.png"}) {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          nieruchomosci: file(relativePath: {eq: "images/specializationsCarousel/nieruchomosci.png"}) {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          spolkiHandlowe: file(relativePath: {eq: "images/specializationsCarousel/spolkihandlowe.png"}) {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          umowy: file(relativePath: {eq: "images/specializationsCarousel/umowy.png"}) {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          przestepstwaGospodarcze: file(relativePath: {eq: "images/specializationsCarousel/przestepstwa.png"}) {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          podatki: file(relativePath: {eq: "images/specializationsCarousel/podatki.png"}) {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          rodo: file(relativePath: {eq: "images/specializationsCarousel/rodo.png"}) {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          wypadkiDrogowe: file(relativePath: {eq: "images/specializationsCarousel/wypadki.png"}) {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          prawoRodzinne: file(relativePath: {eq: "images/specializationsCarousel/praworodzinne.png"}) {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          prawoPracy: file(relativePath: {eq: "images/specializationsCarousel/prawopracy.png"}) {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          sporySadoweIarbitrazowe: file(relativePath: {eq: "images/specializationsCarousel/sporysadowe.png"}) {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
      }
    `}
    render={data => (
      <div class="carousel__item">                 
            {props.spec.split(',').map((el, i) => (                                  
                <div className="carousel__box" key={i}>      
                    <Img className="carousel__img" fluid={data[utils.removePolishSigns(el)].childImageSharp.fluid} />
                    <Link to={`/specjalizacje/${utils.camelToDash(utils.removePolishSigns(el))}/`}> 
                    <div className="carousel__text">
                        <Image type="icon" src={utils.removePolishSigns(el)} />
                        <p>{utils.splitIntoWords(utils.capitalizeFirstLetter(el))}</p>                  
                    </div>
                    </Link> 
                </div>                                                          
            ))}             
      </div>
    )}
  />
    
)

SpecCarouselItem.propTypes = {
  spec: PropTypes.string.isRequired,
}

export default SpecCarouselItem 